@import 'utils/variables';
@import 'components/type';
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,400;0,700;1,100;1,400;1,700&display=swap');

*{
    margin: 0;
}

.box__body{
    background-color: #ffffff;
    background-image: url(../assets/images/ellipse4.svg);
    background-size: 12px;
    background-position-x: 2px;
    background-position-y: 15px;
    color: black;
    font-family: 'IBM Plex Mono', monospace;
}

.box__ui{
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    grid-template-rows: 1.5fr 6fr 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }

.a--alt{
    position: absolute;
    grid-area: 1 / 1 / 2 / 2;
    margin: 30px 0 0 2%;

    & img{
        width: 85px;
    }
}

.folder-open{
    display: none;
}


.box__rac{

    a{
        text-decoration: none;
        height: 140px;
        margin: 10px 10px;

        & img{
            width: 85px;
        }

        &:hover .folder-open{
            display: inline;
        }

        &:hover .folder-closed{
            display: none;
        }
    }

    &--left{
        display: flex;
        grid-area: 2 / 1 / 3 / 2;
        align-content: flex-end;
        flex-wrap: wrap;
        justify-content: center;
    }

    &--right{
        grid-area: 2 / 3 / 3 / 4;
        display: flex;
        align-content: flex-end;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__app-name{
        font-size: 12px;
        color: black;
        background: #FFFFFF;
        border: 3px solid #000000;
        border-radius: 4px;
        width: fit-content;
        padding: 0 10px;
        text-align: center;
        margin: 0 auto;
    }
}

.scale-in-br {
	-webkit-animation: scale-in-br 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-br 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-in-br {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
  }

.box__log{
    grid-area: 2 / 2 / 3 / 3;
    height: 100%;
    width: fit-content;
    display: flex;
    align-items: center;
    margin: 0 auto;
    min-height: 606px;

    &__window{
        background-color: #D9D9D9;
        width: 1000px;
        height: 600px;
        border: solid 3px black;
        border-radius: 12px;

        &__onglet{
            background-color: #FF8C39;
            width: 1000px;
            height: 40px;
            border: solid 3px black;
            border-radius: 12px 12px 0 0;
            margin-left: -3px;
            margin-top: -3px;

            &__title{
                font-weight: normal;
                margin-top: 0;
                margin-left: 30px;
            }

            &--close{
                background-image: url(../assets/images/Cross.svg);
                background-size: cover;
                height: 22px;
                width: 22px;
                float: right;
                margin-right: 10px;
                margin-top: -31px;
                cursor: pointer;
            }
        }

        &__box{
            height: 557px;
            width: 1000px;
            overflow-y: scroll;
            overflow-x: hidden;

            &__image{
                width: 974px;
            }

            &::-webkit-scrollbar{
                width: 26px;
                background-color: #F2F2F2;
                outline: black solid 3px;
                border-radius: 0 0 12px 0;
            }

            &::-webkit-scrollbar-track{
                width: 26px;
            }

            &::-webkit-scrollbar-thumb{
                width: 10px;
                background-color: #EBB536;
                border: solid 3px #F2F2F2;
                border-radius: 20px;
            }
        }

        &__link{
            background-color: #EBB536;
            border: solid 3px black;
            border-radius: 0 0 12px 12px;
            color: black;
            text-decoration: none;
            transform: translateY(-3px);
            transform: translateX(-3px);
            width: 70%;
            margin: 0 auto;
            height: 40px;
            text-align: center;

            & a{
                text-decoration: none;
                color: #000000;
                font-weight: bold;
                position: absolute;
                top: 50%;
                left: 50%;
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
        }
    }
}

#tfa{
    display: block;
}

#rux{
    display: none;
}

#iolce{
    display: none;
}

#dataplay{
    display: none;
}

#btntfa{
    cursor: pointer;
}

#btnrux{
    cursor: pointer;
}

#btniolce{
    cursor: pointer;
}

#btndataplay{
    cursor: pointer;
}

  