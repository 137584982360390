body {
    font-family: sans-serif;
    font-size: $size-text;
    line-height: 1.5;
    color: $c-text;
}

.title {
    color: $c-alt;
}
